import * as React from 'react'
import { Bar, Line } from 'react-chartjs-2'
import Layout from '../components/layout'
import Seo from '../components/seo'

const DataVizPage = ({ pageContext }) => {
  /*
  const barChartData2 = {
    labels: ["Planned Value (PV)", "Earned Value (EV)", "Total to Date (AC)"],
    datasets: [
      {
        label: "Planned Value (PV)",
        backgroundColor: ['rgba(135,206,250,0.2)', 'rgba(119,221,119,0.2)', 'rgba(255,99,132,0.2)'],
        borderColor: ['rgba(135,206,250,1)', 'rgba(119,221,119,1)', 'rgba(255,99,132,1)'],
        borderWidth: 1,
        hoverBackgroundColor: ['rgba(135,206,250,0.4)', 'rgba(119,221,119,0.4)', 'rgba(255,99,132,0.4)'],
        hoverBorderColor: ['rgba(135,206,250,1)', 'rgba(119,221,119,1)', 'rgba(255,99,132,1)'],
        data: [68000]
      },
      {
        label: "Earned Value (EV)",
        backgroundColor: ['rgba(135,206,250,0.2)', 'rgba(119,221,119,0.2)', 'rgba(255,99,132,0.2)'],
        borderColor: ['rgba(135,206,250,1)', 'rgba(119,221,119,1)', 'rgba(255,99,132,1)'],
        borderWidth: 1,
        hoverBackgroundColor: ['rgba(135,206,250,0.4)', 'rgba(119,221,119,0.4)', 'rgba(255,99,132,0.4)'],
        hoverBorderColor: ['rgba(135,206,250,1)', 'rgba(119,221,119,1)', 'rgba(255,99,132,1)'],
        data: [61000]
      },
      {
        label: "Actual Cost (AC)",
        backgroundColor: 'rgba(255,99,132,0.2)',
        borderColor: 'rgba(255,99,132,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        hoverBorderColor: 'rgba(255,99,132,1)',
        data: [70000]
      }
    ]
  }
  const barChartData3 = {
    labels: ["Planned Value (PV)", "Earned Value (EV)", "Actual Cost (AC)"],
    xAxisID: "something",
    datasets: [
      {
        label: "Total to Date",
        backgroundColor: ["#3e95cd", "#3cba9f", "#c45850"],
        data: [68000, 61000, 70000]
      }
    ]
  }
  */
  const barChartData = {
    labels: ['Cumulative Totals'],
    datasets: [
      {
        label: 'Planned Value (PV)',
        data: [68000],
        backgroundColor: '#3e95cd',
        borderColor: '#fff',
        borderWidth: 2
      },
      {
        label: 'Actual Cost (AC)',
        data: [70000],
        backgroundColor: '#c45850',
        borderColor: '#fff',
        borderWidth: 2
      },
      {
        label: 'Earned Value (EV)',
        data: [61000],
        backgroundColor: '#3cba9f',
        borderColor: '#fff',
        borderWidth: 2
      }
    ]
  }
  const lineChartData = {
    labels: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ],
    datasets: [
      {
        label: 'Planned Value (PV)',
        data: [
          0, 5000, 12000, 22000, 30000, 35000, 40000, 45000, 50000, 55000,
          65000, 68000
        ],
        borderColor: '#3e95cd',
        fill: false
      },
      {
        label: 'Actual Cost (AC)',
        data: [0, 5000, 14000, 24000, 33000, 37000, 43000, 48000, 52000],
        borderColor: '#c45850',
        fill: false
      },
      {
        label: 'Earned Value (EV)',
        data: [0, 5000, 10000, 20000, 25000, 30000, 35000, 40000, 45000],
        borderColor: '#3cba9f',
        fill: false
      }
    ]
  }

  return (
    <Layout>
      <Seo title="Data Visualization" />
      <main className="l-main">
        <div className="l-constrained text-highlight">
          <h1>Data Visualization</h1>
          <article>
            <h2>Earned Value Management - Summary</h2>
            <Bar
              type="bar"
              data={barChartData}
              width={100}
              height={50}
              options={{
                responsive: true,
                maintainAspectRatio: true,
                plugins: {
                  title: {
                    display: true,
                    text: 'Project Cumulative Totals to Date (September)',
                    position: 'bottom'
                  }
                }
              }}
            />
          </article>
          <article>
            <h2>Earned Value Management - Trend</h2>
            <Line
              type="line"
              data={lineChartData}
              options={{
                cubicInterpolationMode: 'monotone',
                responsive: true,
                maintainAspectRatio: true,
                plugins: {
                  title: {
                    display: true,
                    text: 'Project S-Curve',
                    position: 'bottom'
                  }
                },
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true
                      }
                    }
                  ]
                }
              }}
            />
            <br />
            <br />
            <br />
          </article>
        </div>
      </main>
    </Layout>
  )
}

export default DataVizPage
